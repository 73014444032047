import { Routes, Route, BrowserRouter } from "react-router-dom";

import './App.css';

import { Provider } from "react-redux";
import store from "./store";
import Token from "./pages/Token";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Web3ModalProvider } from "./components/Web3ModalProvider";

const App = () => {
  return (
    <Web3ModalProvider>
      <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Token />}>
              </Route>
            </Routes>
          </BrowserRouter>
        <ToastContainer />
      </Provider>
    </Web3ModalProvider>
  );
};

export default App;
