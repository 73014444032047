import React, { useState } from "react";

const Footer = () => {
  return (
    <>
      <footer className="w-full relative bg-black pt-[80px] md:pt-[113px] px-[30px]">
        <div className="max-w-[1223px] flex flex-col gap-[20px] w-full mx-auto">
        
          <div className="w-full">
            <div className="flex flex-wrap lg:flex-nowrap lg:gap-[50px] lg:gap-[100px]">
              <div className="w-full lg:w-1/3">
                <img
                  alt="close"
                  fetchpriority="high"
                  width="160"
                  height="160"
                  decoding="async"
                  data-nimg="1"
                  className="mb-4 pt-10 md:pt-0"
                  src="/images/infinidium.png"
                  style={{ color: "transparent" }}
                />
                <p className="text-[20px] text-white lg:max-w-[385px] w-full font-[400]">
                 Revolutionizing Data Center and Crypto Mining Infrastructure through Innovative Cooling and Power Solutions
                </p>
              </div>
              <div className="w-full sm:w-[65%] lg:w-1/3 flex gap-[50px] mt-[70px]">
                <div className="w-1/2 flex flex-col gap-[10px] text-[#A0A0A0] sm:text-[#9EB0CB] text-[18px]">
                  <a className="hover:text-white" href="https://infinidium.ca/about-us">
                    About Us
                  </a>
                  <a className="hover:text-white" href="/">
                    Tokens
                  </a>
                  <a className="hover:text-white" href="https://infinidium.ca/contact">
                    Contact
                  </a>
                  <a className="hover:text-white" href="https://infinidium.ca/news">
                    News
                  </a>
                 
                </div>
                <div className="w-1/2 flex flex-col gap-[10px] text-[#A0A0A0] sm:text-[#9EB0CB] text-[18px]">
                  <a
                    target="_blank"
                    className="hover:text-white underline"
                    href="https://t.me/infinidium-group"
                  >
                    Join Telegram
                  </a>
                  <a
                    target="_blank"
                    className="hover:text-white underline"
                    href="https://infinidium.ca"
                  >
                    Whitepaper
                  </a>
                  <a
                    target=""
                    className="hover:text-white false"
                    href="/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                  <a
                    target=""
                    className="hover:text-white false"
                    href="/terms-of-use"
                  >
                    Terms Policy
                  </a>
                 
                </div>
              </div>
              <div className="md:max-w-[200px] lg:max-w-[252px] w-[252px] sm:w-[35%] md:w-1/3 mt-[70px]">
                <p className="co-orange text-[20px]">Follow us!</p>
                <div className="flex md:flex-col mt-[20px]">
                  <div className="flex ">
                    <a target="_blank" href="https://twitter.com/infinidium-group">
                      <img
                        alt="x"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/X.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                    <a target="_blank" href="https://t.me/infinidium-group">
                      <img
                        alt="Telegram"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/Telegram.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://discord.com"
                    >
                      <img
                        alt="Discord"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/Discord.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/infinidium-group/"
                    >
                      <img
                        alt="Discord"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/Instagram.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                  </div>
                  <div className="flex  justify-center">
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@infinidium-group"
                    >
                      <img
                        alt="Discord"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/youtubeicon.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.reddit.com/r/infinidium/"
                    >
                      <img
                        alt="Discord"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer"
                        src="../images/reddit.svg"
                        style={{ color: "transparent" }}
                      />
                    </a>
                    {/* <a
                      target="_blank"
                      href="https://www.coingecko.com/en/coins/pikamoon/"
                    >
                      <img
                        alt="Discord"
                        fetchpriority="high"
                        width="50"
                        height="50"
                        decoding="async"
                        data-nimg="1"
                        className="hover:scale-[1.2] transtionHover cursor-pointer w-[51px]"
                        src="../images/general-icon.svg"
                        style={{ color: "transparent" }}
                      />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-[20px] md:gap-0 md:items-center pt-[20px] md:pt-20 border-b border-[#D4D2E3] pb-12">
              <div className="w-full">
                <div className="max-w-[750px] mx-auto flex flex-col md:items-center">
                  <p className="co-orange font-bold mb-[5px] ">Disclaimer:</p>
                  <p className="co-white text-[14px] leading-[20px] md:text-center">
                  This is for informational purposes only and does not constitute financial or investment advice. Investors should conduct their own due diligence before participating in the ICO.
                  </p>
                </div>
              </div>
            </div>
            <p className="text-sm text-white py-[26px] md:py-[40px] text-center">
              Infinidium copyrights&nbsp;© 2024. All rights reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
