import React, { useState, useEffect } from "react";
import classnames from "classnames";

import Footer from "../layout/Footer";
import Blog from "../layout/Blog";

import {
  useAccount,
  useReadContract,
  useBalance,
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";

import { ethers, BigNumber } from "ethers";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import CanvasJSReact from '@canvasjs/react-charts';

import PresaleABI from "../abis/ico.json";
import { ToastContainer, toast } from "react-toastify";

import unsecuredCopyToClipboard from "../utils/clipboard";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const presaleContract = "0x482b40040cef5c92357cd2b5e5d6ab777d079ec1";

const Token = () => {

  const { address, isConnecting, isDisconnected } = useAccount();

  
  const options = {
    exportEnabled: false,
    animationEnabled: true,
    // title: {
    //   text: "Website Traffic Sources"
    // },
    data: [{
      type: "pie",
      startAngle: 75,
      toolTipContent: "<b>{label}</b>: {y}%",
      showInLegend: "true",
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}%",
      dataPoints: [
        { y: 66.6, label: "Presale" },
        { y: 10, label: "Retained Treasury" },
        { y: 15, label: "Founders" },
        { y: 8, label: "Directors" },
        { y: 0.4, label: "Advisiors" }
      ]
    }]
  }


  const [tokenAmount, setTokenAmount] = useState("0");

  const [activePhase, setActivePhase] = useState(0);

  const [raisedAmount, setRaisedAmount] = useState(0);

  const [tokenPrice, setTokenPrice] = useState(0);

  const [totalSold, setTotalSold] = useState(0);

  const [maxTokens, setMaxTokens] = useState(0);

  const [phase, setPhase] = useState([]);

  const [bnbAmount, setBnbAmount] = useState("0");

  const [bnbBalance, setBnbBalance] = useState("0");

  const [claimablePhase1, setClaimablePhase1] = useState("0");
  const [claimablePhase2, setClaimablePhase2] = useState("0");
  const [claimablePhase3, setClaimablePhase3] = useState("0");
  const [claimablePhase4, setClaimablePhase4] = useState("0");

  const [totalClaimable, setTotalClaimable] = useState("0");

  const [claimableActive, setClaimableActive] = useState("1");

  // const [finalBNB, setFinalBNB] = useState(0);

  const activePhaseTmp = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "activePhase",
    // chainId:97
  });

  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    isRejected: isRejected,
  } = useWaitForTransactionReceipt({
    hash,
  });
  useEffect(() => {
    if (error) {
      console.log("contract write error", error);
      const startIndex = error.message.indexOf("following reason:");
      const endIndex = error.message.indexOf("Contract Call:");

      // console.log("startIndex", startIndex);
      // console.log("endIndex", endIndex);
      const modifiedSentence = error.message.slice(startIndex + 17, endIndex);
      // console.log("modifiedSentence", modifiedSentence)
      toast(modifiedSentence);
    }
  }, [error]);

  // console.log("hash", hash);
  useEffect(() => {
    if (activePhaseTmp.data) {
      setActivePhase(activePhaseTmp.data.toString());
    }
  }, [activePhaseTmp]);

  const phaseTmp = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "phase",
    args: [activePhase],
    // chainId:97
  });

  const tokenAmountTmp = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "estimatedToken",
    args: [bnbAmount],
    // chainId:97
  });

  const bnbBalanceTmp = useBalance({
    address: address,
  });

  useEffect(() => {
    if (bnbBalanceTmp.data?.value) {
      // const weiAmount = ethers.utils.parseUnits(bnbBalanceTmp.data.value.toString(), 'ether');
      // setBnbBalance(weiAmount);

      setBnbBalance(bnbBalanceTmp.data.value.toString());
      // console.log(bnbBalanceTmp.data.value.toString());
    }
  }, [bnbBalanceTmp]);

  useEffect(() => {
    if (isConfirmed) {
      toast("Transaction is Succeed!");
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (isRejected) {
      toast("You rejected to sign transaction!");
    }
  }, [isRejected]);

  useEffect(() => {
    if (tokenAmountTmp.data) {
      // console.log(phaseTmp.data);
      // const tokenAmountWei = BigNumber.from(tokenAmountTmp.data.toString());
      // const tokenAmountbnb = ethers.utils.formatUnits(tokenAmountWei, 18);
      setTokenAmount(tokenAmountTmp.data.toString());
    }
  }, [tokenAmountTmp]);

  useEffect(() => {
    if (phaseTmp.data) {
      // console.log(phaseTmp.data);
      setPhase(phaseTmp.data);
      // setTokenPrice(phase[4].toString())
      // console.log(phaseTmp.data[4].toString());
    }
  }, [phaseTmp]);

  useEffect(() => {
    if (phase.length > 0) {
      setTokenPrice((phase[4].toString() * 1) / 10 ** 18);
      setRaisedAmount((phase[3].toString() * 1) / 10 ** 18);
      // console.log("totalsold",phase[2].toString() * 1 )
      // setMaxTokens((phase[1].toString() * 1) / 10 ** 18);
      setMaxTokens(phase[1].toString() * 1);
      setTotalSold((phase[2].toString() * 1) / 10 ** 18);

      // console.log((phase[1].toString() * 1) / (10 ** 18));
      // console.log(phase[2].toString() * 1);
    }
  }, [phase]);

  const deserveTmpPhase1 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "deservedAmount",
    args: [address, 1],
  });

  const claimedAmountPhase1 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "claimedAmount",
    args: [address, 1],
  });

  useEffect(() => {
    //  console.log("wat is",deserveTmpPhase1.data.toString() -  claimedAmountPhase1.data.toString());
    if (deserveTmpPhase1 && claimedAmountPhase1) {
      try {
        setClaimablePhase1(
          deserveTmpPhase1.data.toString() - claimedAmountPhase1.data.toString()
        );
      } catch (err) {}
    }
  }, [deserveTmpPhase1, claimedAmountPhase1]);

  const deserveTmpPhase2 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "deservedAmount",
    args: [address, 2],
  });

  const claimedAmountPhase2 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "claimedAmount",
    args: [address, 2],
  });

  useEffect(() => {
    if (deserveTmpPhase2 && claimedAmountPhase2) {
      try {
        setClaimablePhase2(
          deserveTmpPhase2.data.toString() - claimedAmountPhase2.data.toString()
        );
      } catch (err) {}
    }
  }, [deserveTmpPhase2, claimedAmountPhase2]);

  const deserveTmpPhase3 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "deservedAmount",
    args: [address, 3],
  });

  const claimedAmountPhase3 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "claimedAmount",
    args: [address, 3],
  });

  useEffect(() => {
    if (deserveTmpPhase3 && claimedAmountPhase3) {
      try {
        setClaimablePhase3(
          deserveTmpPhase3.data.toString() - claimedAmountPhase3.data.toString()
        );
      } catch (err) {}
    }
  }, [deserveTmpPhase3, claimedAmountPhase3]);

  const deserveTmpPhase4 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "deservedAmount",
    args: [address, 4],
  });

  const claimedAmountPhase4 = useReadContract({
    abi: PresaleABI,
    address: presaleContract,
    functionName: "claimedAmount",
    args: [address, 4],
  });

  useEffect(() => {
    if (deserveTmpPhase4 && claimedAmountPhase4) {
      try {
        setClaimablePhase3(
          deserveTmpPhase4.data.toString() - claimedAmountPhase4.data.toString()
        );
      } catch (err) {}
    }
  }, [deserveTmpPhase4, claimedAmountPhase4]);

  useEffect(() => {
    setTotalClaimable(claimablePhase1 + claimablePhase2 + claimablePhase3 + claimablePhase4);
  }, [claimablePhase1, claimablePhase2, claimablePhase3, claimablePhase4]);


  return (
    
    <>
      <div>
       
        <div className="z-[9] co-navbar  px-[20px] h-[80px] border-b border-b-[2.5px] border-black text-black bg-gradient-to-r from-[#584b33] to-[#78674a] hidden md:block sticky top-[-2px] bg-[#ffffff a-z-30">
          <div className="max-w-[1223px] w-full h-full mx-auto flex items-center gap-x-2 flex-wrap md:flex-nowrap lg:gap-x-10">
            <div>
              <a href="https://infinidium.ca">
                <img
                  alt="close"
                  loading="lazy"
                  width="80"
                  height="80"
                  decoding="async"
                  data-nimg="1"
                  className="relative  transition-transform ease-in-out z-[1] hover:scale-[1.2] transform origin-center will-change-transform"
                  style={{ color: "transparent" }}
                  src="/images/infinidium.png"
                />
              </a>
            </div>

            <ul className="flex gap-[10px] lg:gap-[25px]">
              <li>
                <a
                  className=" text-white menu-link-effect text-[16px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="https://infinidium.ca/about-us"
                >
                  About Us
                  <span className="absolute right-[-14px] top-0 opacity-0"></span>
                </a>
              </li>
              <li>
                <a
                  className="false menu-link-effect text-[16px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="https://infinidium.ca/contact"
                >
                  Contact
                  <span className="absolute right-[-14px] top-0 opacity-0"></span>{" "}
                </a>
              </li>
              <li>
                <a
                  className="false menu-link-effect text-[16px] lg:text-[18px] font-[500] tracking-[-0.36px] capitalize hover:bg-black rounded-[10px] p-[7.3px_15px_7.3px_20px] hover:text-white relative whitespace-nowrap"
                  href="https://infinidium.ca/news"
                >
                  News
                  <span className="absolute right-[-14px] top-0 opacity-0"></span>{" "}
                </a>
              </li>
           
            </ul>

            <div className="ml-auto flex items-center gap-x-1 lg:gap-x-2 2xl:gap-x-5" />
    
            <a>
              <w3m-button>Connect Wallet</w3m-button>
            </a>

            <p className="bg-transparent outline-0 font-semibold text-[18px] capitalize tracking-[-0.36px]">
              ENG
            </p>
          </div>
        </div>
        {/* bg-[#c7bc93] */}
        <div className="pt-[60px] pb-[50px]  bg-cover bg-center-top relative min-h-[1000px]">
          <div className="absolute top-[60px] bottom-0 left-0 right-0 !pointer-events-none">
            <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[0]">
            
            </div>
            <div className="flex justify-between items-center lg:sticky left-0 right-0 top-[450px] mt-[450px]">
             
            </div>
          </div>
          <section className="max-w-[1256px] w-full mx-auto px-[15px] sm:px-[30px] pb-[60px] relative z-[2]">
            <div className="flex flex-col lg:flex-row justify-between md:gap-[50px]">
              <div className="w-full md:w-1/2 flex flex-col gap-[20px]">
                <h5 className="orangeTextClash ">
                  How to import INFI Tokens to Your Wallet
                </h5>
                <p className="text-[20px] font-normal leading-[30px] tracking-[-0.4px]">
                  After buying your INFI tokens, they may not automatically
                  appear in your wallet.
                </p>
                <p className="text-[20px] font-normal leading-[30px] tracking-[-0.4px]">
                  To view your tokens, add them manually using the token
                  contract address (to be provided upon deployment and audit on
                  the main net).
                </p>
                <p className="text-[20px] font-normal leading-[30px] tracking-[-0.4px]">
                  Here's a quick guide:
                </p>
                <ul className="game-fi">
                  <li>1. Access your web3 wallet (e.g., Metamask).</li>
                  <li>2. Navigate to the "Add Token" section.</li>
                  <li>
                    {/* 3. Enter the INFI token contract: <br />
                    <a target="_blank" href="https://etherscan.io/address/0x5751c08c23cad8f569d045f72654d388fe6d1e20"> */}
                    <span className="break-words font-bold">
                    0x5751c08c23cad8f569d045f72654d388fe6d1e20
                    </span>
                    {/* </a> */}
                  </li>
                  <li>
                    4. Confirm the addition to see your INFI tokens in your
                    wallet.
                  </li>
                </ul>
              </div>
              <div className="lg:max-w-[600px] w-full">
              {!isDisconnected && (
                <section>
                  <div className="cardBuyPika pt-[5px] pb-[5px] pl-[32px] pr-[32px]">
                    <p className="blackTextClash text-[24px] md:text-[32px] text-center py-5">
                      INFI Token Presale (Round {activePhase})
                    </p>
                    <div>
                      <div className="flex justify-between">
                        <span className="text-sm font-medium text-blue-700 dark:text-white"></span>
                      </div>
                      <div className="w-[100%] rounded-full h-[20px] relative">
                        <div
                          className="backgroundOrangeLinear h-[30px] rounded-full"
                          style={{ width: "100%" }}
                        >
                          <p className="absolute right-[15px] text-[20px] font-bold co-white uppercase">
                          {(100 - (totalSold / maxTokens) * 100).toFixed(5)}
                              %
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col py-5 space-y-4">
                        <div className="flex justify-between items-center gap-[15px]">
                          <p className="co-gray text-[18px] md:text-[22px]">
                            Total Supply:
                          </p>
                          <div className="text-right flex flex-wrap justify-end items-center text-[14px] md:text-[18px]">
                            <span className="uppercase font-bold">
                              1,000,000,000 
                            </span>
                            <span className="uppercase font-bold"> &nbsp; INFI</span>
                            <svg
                              className="ml-2 w-5 h-5"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M10 7H10.01M9 10H10V14H11M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10Z"
                                stroke="#9EB0CB"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div className="flex gap-[15px] justify-between items-center h-10">
                          <p className="co-gray text-[18px] md:text-[22px]">
                            Supply For Presale:
                          </p>
                          <div className="flex items-center ">
                            <span className="uppercase font-bold text-[14px] md:text-[18px]">
                            {maxTokens}
                            </span>
                            <span className="uppercase font-bold"> &nbsp;INFI</span>
                            <svg
                              className="ml-2 w-5 h-5"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M10 7H10.01M9 10H10V14H11M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10Z"
                                stroke="#9EB0CB"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div className="flex gap-[15px] justify-between items-center h-10">
                          <p className="co-gray text-[18px] md:text-[22px]">
                            Total Raised:
                          </p>
                          <div className="flex items-center ">
                            <span className="uppercase font-bold text-[14px] md:text-[18px]">
                            {raisedAmount} ETH
                            </span>
                            <svg
                              className="ml-2 w-5 h-5"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M10 7H10.01M9 10H10V14H11M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10Z"
                                stroke="#9EB0CB"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div className="claim-buttons">
                          <div className="btn  p-2 w-full h-[60px] my-3 z-[9999] ">
                            1 INFI = {tokenPrice} USD
                          </div>
                          {/* <button
                            className="btnConnectWallet w-full h-[60px] my-3 z-[9999]"
                            id="connectWalletButton"
                          >
                            
                          </button> */}
                          {address && (
                            <>
                              <div className="flex items-center border-2 border-solid border-#C0CCDD rounded h-[60px]">
                                <input
                                  className="flex-grow focus:outline-none p-[10px] md:p-2 rounded"
                                  type="number"
                                  style={{
                                    color: "black",
                                    maxWidth: "768px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                  }}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      const weiAmount =
                                        ethers.utils.parseUnits(
                                          e.target.value.toString(),
                                          "ether"
                                        );
                                      setBnbAmount(weiAmount);
                                    }
                                  }}
                                />
                                <button className="bg-custom-color text-white px-1 py-4 md:px-4 md:py-4 rounded-tr-md rounded-br-md rounded-bl-none rounded-tl-none flex-shrink-0 flex-basis-auto w-32">
                                 ETH
                                </button>
                              </div>
                              <br/>
                              <div className="flex items-center border-2 border-solid border-#C0CCDD rounded h-[60px]">
                                <input
                                  className="flex-grow focus:outline-none p-[10px] md:p-2 rounded"
                                  value={
                                    isDisconnected
                                      ? setTokenAmount(0)
                                      : tokenAmount
                                  }
                                  readOnly="true"
                                  style={{
                                    color: "black",
                                    maxWidth: "768px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                  }}
                                />
                                <button className="bg-custom-color text-white px-1 py-4 md:px-4 md:py-4 rounded-tr-md rounded-br-md rounded-bl-none rounded-tl-none flex-shrink-0 flex-basis-auto w-32"
                                
                                >
                                 INFI
                                </button>
                              </div>
                              <button
                                className="btnConnectWallet w-full h-[60px] my-3 z-[9999] text-white"
                                id="buyButton"
                                disabled={isPending}
                                onClick={async () => {
                                  if (bnbAmount == 0) {
                                    toast("Input BNB amount to buy tokens");
                                  }
                                  if (
                                    BigNumber.from(bnbBalance).lt(
                                      BigNumber.from(bnbAmount)
                                    )
                                  ) {
                                    toast("Your Balance is not enough!");
                                  } else {
                                    console.log("bnbAmount", bnbAmount);
                                    const res = writeContract({
                                      abi: PresaleABI,
                                      address: presaleContract,
                                      functionName: "buyTokensEth",
                                      value: bnbAmount,
                                    });
                                  }
                                }}
                              >{isPending || isRejected
                                ? "Confirming..."
                                : "Buy Now"}</button>
                                <div className="flex gap-[15px] justify-between items-center h-10">
                                  <p className="co-gray text-[18px] md:text-[22px]">
                                    Total Claimable:
                                  </p>
                                  <div className="flex items-center ">
                                    <span className="uppercase font-bold text-[14px] md:text-[18px]">
                                      {totalClaimable} INFI
                                    </span>
                                    <svg
                                      className="ml-2 w-5 h-5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        d="M10 7H10.01M9 10H10V14H11M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10Z"
                                        stroke="#9EB0CB"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                                <div className="flex items-center border-2 border-solid border-#C0CCDD rounded h-[60px]">
                                  <select
                                    className="flex-grow focus:outline-none p-[10px] md:p-2 rounded"
                                    type="number"
                                    style={{
                                      color: "black",
                                      maxWidth: "768px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      textAlign: "center",
                                    }}
                                    onChange={(e) => {
                                      console.log(
                                        "claimableActive",
                                        e.target.value
                                      );
                                      setClaimableActive(e.target.value);
                                    }}
                                  >
                                    <option value={1}>
                                      Round 1 ({claimablePhase1} INFI Claimable)
                                    </option>
                                    <option value={2}>
                                      Round 2 ({claimablePhase2} INFI Claimable)
                                    </option>
                                    <option value={3}>
                                      Round 3 ({claimablePhase3} INFI Claimable)
                                    </option>
                                    <option value={4}>
                                      Round 4 ({claimablePhase4} INFI Claimable)
                                    </option>
                                  </select>
                                </div>

                                <div className="buttons-claiming flex justify-between">
                                  <button
                                    className="btn btn-transparent w-full h-[60px] my-3 z-[9999] "
                                    style={{ width: "100%" }}
                                    onClick={async () => {
                                      console.log(
                                        "claimableActive",
                                        claimableActive
                                      );
                                      const res = writeContract({
                                        abi: PresaleABI,
                                        address: presaleContract,
                                        functionName: "claim",
                                        args: [claimableActive],
                                      });
                                    }}
                                  >
                                    {isPending || isRejected
                                      ? "Confirming..."
                                      : "Claim INFI "}
                                  </button>
                                </div>
                            </>
                          )}
                        </div>
                        <p className="text-lg font-medium text-center copy-text">
                          Copy this Address to ADD INFI to your Wallet
                        </p>
                        <div className="flex items-center border-2 border-solid border-#C0CCDD rounded h-[60px]">
                          <input
                            readonly=""
                            className="flex-grow focus:outline-none p-[10px] md:p-2 rounded"
                            type="text"
                            value="0x5751c08c23cad8f569d045f72654d388fe6d1e20"
                            style={{
                              color: "rgb(192, 204, 221)",
                              maxWidth: "768px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                            }}
                          />
                          <button className="bg-custom-color text-white px-1 py-4 md:px-4 md:py-4 rounded-tr-md rounded-br-md rounded-bl-none rounded-tl-none flex-shrink-0 flex-basis-auto">
                            <img
                              src="../images/copy-link.svg"
                              alt="Copy Link"
                              className="w-6 h-[25px]"
                              onClick={()=>{
                                unsecuredCopyToClipboard("0x5751c08c23cad8f569d045f72654d388fe6d1e20")
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </section>
              )}
              {isDisconnected && (
                  <div className="cardBuyPika pt-[5px] pb-[5px] pl-[32px] pr-[32px] text-center align-center" style={{"minHeight": "500px"}}>
                    <p className="blackTextClash text-[24px] md:text-[32px] text-center py-5">
                      You need to connect Web3 wallet to buy INFI Token
                    </p>
                    <a className="padding-[10px] pl-[20px]  text-center md:p-0 m-auto flex justify-center items-center">
                      <w3m-button className="m-auto">Connect Wallet</w3m-button>
                    </a>
                    <br />
                  </div>
                )}
              </div>
              <div className="lg:max-w-[600px] w-full block md:hidden mt-[25px]">
                <p className="font-medium text-black tracking-[-0.56px] text-[28px] pb-4 block">
                  Start Earning Rewards - INFI Staking Will Become Live After
                  Launch
                </p>
                <p className="text-xl font-normal pb-8 leading-[32px] block">
                  <span className="text-red-150 ">*</span>Rewards are calculated
                  based on your share of the INFI staking pool &amp; the annual
                  returns percentage.
                </p>
                <a
                  id="bgWhiteCoBlackBorderBlack"
                  className="inline-block "
                  href="#"
                >
                  <div className="relative">
                    <svg
                      width="240"
                      height="60"
                      fill="#F24822"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer transition duration-1000 ease-out text-[25px]"
                    >
                      <path
                        d="M229.808 35.6904C231.222 34.0552 232 31.9658 232 29.8042L232 10C232 5.02944 227.971 1 223 1L9.99999 1.00002C5.02943 1.00002 0.999995 5.02946 0.999996 10L0.999999 50C1 54.9706 5.02943 59 9.99999 59L205.539 59C208.153 59 210.638 57.8636 212.347 55.8862L229.808 35.6904Z"
                        stroke="none"
                        stroke-width="2"
                      ></path>
                      <text x="50%" y="40" fill="white" text-anchor="middle">
                        Buy Now
                      </text>
                    </svg>
                    <img
                      alt="Header"
                      fetchpriority="high"
                      width="240"
                      height="17"
                      decoding="async"
                      data-nimg="1"
                      className="absolute bottom-[-26px] z-[-1] right-4"
                      src="../images/shadowImage.png"
                      style={{ color: "transparent" }}
                    />
                  </div>
                </a>
                <div className="flex gap-x-3 pt-[36px]">
                  <img
                    alt="arrow"
                    fetchpriority="high"
                    width="15"
                    height="15"
                    decoding="async"
                    data-nimg="1"
                    className=""
                    src="/images/arrow.svg"
                    style={{ color: "transparent" }}
                  />
                  <p className="capitalize font-semibold fontClash text-[22px]">
                    More updates coming soon
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row gap-[50px] py-[40px]">
              <div className="w-full lg:w-1/2 flex flex-col gap-[30px]">
                <h1 className="orangeTextClash">Executive Summary</h1>
                <p className="text-[20px] leading-[42px] text-black font-[400]">
                Infinidium is revolutionizing the cloud data center and Blockchain mining industry with its groundbreaking cooling and power infrastructure. Through the development of the world's first net energy-generating cooling system for data centers and High Performance Computing (HPC) operations. 

                </p>
                <p className="text-[20px] leading-[42px] text-black font-[400]">
                The Patent pending (PCT/CA2020/051720) Vortex Vacuum Chamber Passive Air Cooling System, aims to significantly reduce energy consumption by up to 50% and a 100% reduction in water use versus traditional data centers while greatly enhancing operational efficiency with record breaking cost-effectiveness.
                </p>
                <p className="text-[20px] leading-[42px] text-black font-[400]">
                In addition to pioneering cooling and highly efficient power supply and energy storage solutions, Infinidium will establish its own Distributed cloud computing platform, integrating cutting-edge technologies such as artificial intelligence (AI) and robotics. This platform will offer users access to rent our and or third party GPUs with unparalleled incentives and discounts for utilizing the native Infinidium ERC-20 token, fostering a sustainable and cost-efficient ecosystem for data center computing power.
                </p>
                {/* <p className="text-[28px] leading-[42px] text-black font-[400]">
                  Once acquired, INFI can be used to purchase special in-game
                  items via the Infinidium Store - be it magic boosts, health
                  potions, weapons, and more. These upgrades will help bolster
                  your Play-to-Earn journey throughout Dreva.
                </p> */}
                <a
                  className="inline-block mt-[20px]"
                  id="bgRedCoWhite"
                  href="/buy"
                >
                  <svg
                    width="240"
                    height="60"
                    fill="transparent"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer transition duration-1000 ease-out text-[20px] font-medium capitalize"
                  >
                    <path
                      d="M229.619 35.5269C230.994 33.9371 231.75 31.9057 231.75 29.8042L231.75 9.99998C231.75 5.16749 227.832 1.24998 223 1.24998L9.99999 1.25C5.1675 1.25 1.25 5.16751 1.25 10L1.25 50C1.25 54.8325 5.1675 58.75 9.99999 58.75L205.539 58.75C208.08 58.75 210.496 57.6451 212.158 55.7227L229.619 35.5269Z"
                      stroke="black"
                      stroke-width="2"
                    ></path>
                    <a href="/doc/whitepaper.pdf" target="_blank" download>
                    <text x="50%" y="40" fill="black" text-anchor="middle">
                      White Paper
                    </text>
                    </a>
                  </svg>
                </a>
              </div>
              <div className="w-full lg:w-1/2">
               
                <div>
                  <h1 className="orangeTextClash">Infinidium Solution</h1>
                  <p className="textStake my-2">
                   1. Vortex Vacuum Chamber Passive Air-Cooling System
                  </p>
                  <p className="textStake my-6">
                    2. Power Supply & Energy Storage Nanogrid 
                  </p>
                  <p className="textStake my-6">
                    3. Distributed Cloud Computing Platform
                  </p>
                  <p className="textStake my-6">
                    4. AI and Robotics Integration
                  </p>
                  <p className="textStake my-6">
                    5. Nvidia Partnership and Collaborations
                  </p>
                  <p className="textStake my-6">
                    6. Developing a Data Center Hub in Canada with Grants
                  </p>
                  <p className="textStake my-6">
                    7. Carbon Credits & GHGs
                  </p>
                  <p className="textStake my-6">
                    8. 3D Printing of Chambers and Concrete Structures 
                  </p>
                </div>
              </div>
            </div>


            <div>
              {/* Carousel */}
            </div>
            <h1 className="text-center text-[32px] blackTextClash font-semibold pt-6 md:pt-[50px] mb:pb-10 orangeTextClash">
              Tokenomics
            </h1>
            <div className="w-full max-w-[1015px] mx-auto overflow-x-auto hideScrollbar mt-[20px] backgroundOrangeLinear rounded-[30px]">
              <div className="p-[36px] w-full min-w-[800px]">
                <div className="grid grid-cols-1">
                  <table className="min-w-full">
                    <thead className="">
                      <tr className="border-b-2 text-[16px] md:text-[18px] lg:text-[20px] font-[600] border-black text-left">
                        <th className="pb-[10px]">Token Holders</th>
                        <th className="pb-[10px]">Distribution</th>
                        {/* <th className="pb-[10px]">Tokens</th> */}
                        <th className="pb-[10px]">Token Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="pb-[5px] pt-[15px]">
                          Pre Sale - Round 1
                        </td>
                        <td className="pb-[5px] pt-[15px]">16.65%</td>
                        {/* <td className="pb-[5px] pt-[15px]">5.000.000.000</td> */}
                        <td className="pb-[5px] pt-[15px]">$0.05</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Pre Sale - Round 2</td>
                        <td className="py-[5px]">16.65%</td>
                        {/* <td className="py-[5px]">5.000.000.000</td> */}
                        <td className="py-[5px]">$0.10</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Pre Sale - Round 3</td>
                        <td className="py-[5px]">16.65%</td>
                        {/* <td className="py-[5px]">5.000.000.000</td> */}
                        <td className="py-[5px]">$0.20</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Pre Sale - Round 4</td>
                        <td className="py-[5px]">16.65%</td>
                        {/* <td className="py-[5px]">5.000.000.000</td> */}
                        <td className="py-[5px]">$0.40</td>
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Retained Treasury</td>
                        <td className="py-[5px]">10%</td>
                        {/* <td className="py-[5px]">3.750.000.000</td> */}
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Founders</td>
                        <td className="py-[5px]">15%</td>
                        {/* <td className="py-[5px]">6.250.000.000</td> */}
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Directors</td>
                        <td className="py-[5px]">6.5%</td>
                        {/* <td className="py-[5px]">5.000.000.000</td> */}
                      </tr>
                      <tr className="text-left co-white text-[16px] md:text-[18px] lg:text-[20px]">
                        <td className="py-[5px]">Advisiors</td>
                        <td className="py-[5px]">0.4%</td>
                        {/* <td className="py-[5px]">16.000.000.000</td> */}
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center pt-[20px] md:pt-[80px]">
            <CanvasJSChart options = {options}></CanvasJSChart>
              {/* <img
                alt="coins"
                loading="lazy"
                width="450"
                height="390"
                decoding="async"
                data-nimg="1"
                className="md:w-[100%] md:h-[350px] h-[250px] object-fill scale-[1.1] hidden md:block"
                src="/images/chart.svg"
                style={{ color: "transparent" }}
              />
              <img
                alt="coins"
                loading="lazy"
                width="450"
                height="390"
                decoding="async"
                data-nimg="1"
                className="w-[100%] block md:hidden"
                src="../images/mobile-chart.png"
                style={{ color: "transparent" }}
              /> */}
            </div>
     
          </section>
        
        </div>

        {/* <Blog /> */}

        <Footer />
      </div>
    </>
  );
};

export default Token;
